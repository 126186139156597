import React, { useState, useEffect, useMemo, useCallback } from 'react';
import FormEdit from '../../../../common/Form/FormEdit';
import { Box, Grid, Paper, Typography } from '@mui/material';
import ActionHeader from '../../../ActionHeader/ActionHeader';
import { isEmpty } from 'lodash';
import {
  getMandatoryFields,
  getValidationErrors,
} from '../../../utils/userOnboarding/userOnboarding.util';
import ApprovalWorkflowSteps from './AppWorkStepsForm';
import { useDispatch, useSelector } from 'react-redux';
import { ToastThemes, toastMessage } from '../../../../../../constants/common';
import { showToast } from '../../../../../../utils/common.util';
import {
  ApprovalWorkFlowLib,
  ToastMessages,
  stringSubstitute,
} from '../../../constants/onboarding.constants';
import {
  postApprovalWorkflowInfo,
  getApprovalWorkflowConfig,
} from '../../../../../../redux/approvalFlow/action';
import { approvalWorkflowServices } from '../../../../../../services/approvalWokflowService';
import { getNonEmptyObject } from '../../../utils/onboarding.util';
import { userBasedRole, reportingLevels } from './AppWorkConstants';
import { useNavigate } from 'react-router-dom';
import ConditionalModal from './conditionalModal';

const AppWorkEditForm = (props) => {
  const {
    setViewMode,
    formConfig,
    isNew,
    setShowForm,
    steps,
    approvalWorkflowFormDataLib,
    approvalWorkflowFormDataSteps,
    formId,
    stepsDeletedForm,
    outsideBuilderCall,
    setApprovalLibFormState,
    approvalLibFormState,
    outsideSave,
    approvalErrorsLib,
    setApprovalErrorsLib,
    approvalErrorsSteps,
    setApprovalErrorsSteps,
  } = props;
  const { currentUserRoleId } = useSelector((state) => state.main);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [libraryDropDownOptions, setLibraryOptions] = useState([]);
  const [approverTypeDropDownOptions, setApproverTypeDropDownOptions] =
    useState([]);
  const [formInputDropDownOptions, setformInputDropDownOptions] = useState([]);
  const [formNamesDropDownOptions, setFormNamesOptions] = useState([]);
  const [approverRoleIdDropDownOptions, setApproverRoleIdDropDownOptions] =
    useState([]);
  const [approverUserIdDropDownOptions, setApproverUserIdDropDownOptions] =
    useState([]);
  const [moduleNameDropDownOptions, setmoduleNameDropDownOptions] = useState(
    []
  );
  const [validationErrorsLib, setValidationErrorsLib] = useState({});
  const [formState, setFormState] = useState(approvalWorkflowFormDataLib);
  const [sendBackStepDropDownOptions, setSendBackStepDropDownOptions] =
    useState([]);
  const [conditionalCriteria, setConditionalCriteria] = useState([]);
  const [showModalEmailConfigs, setShowModalEmailConfigs] = useState(false);
  const [emailTemplate, setEmailTemplate] = useState([]);
  const [formStepsValidationErrors, setformStepsValidationErrors] = useState(
    []
  );
  const [emailConfigsFormstate,setEmailConfigsFormstate] = useState(outsideBuilderCall?approvalLibFormState?.['emailConfigs'] || {}:approvalWorkflowFormDataLib?.['emailConfigs']|| {});

  useEffect(() => {
    outsideBuilderCall
      ? setApprovalLibFormState(approvalLibFormState)
      : setFormState(approvalWorkflowFormDataLib);
  }, [approvalLibFormState, approvalWorkflowFormDataLib]);

  useEffect(()=>{
    outsideBuilderCall
      ? setApprovalLibFormState((prev)=>{return{...prev,'emailConfigs': emailConfigsFormstate};})
      : setFormState((prev)=>{return{...prev,'emailConfigs': emailConfigsFormstate};});
  },[emailConfigsFormstate]);

  const handleDiscard = () => {
    if (isNew) {
      setShowForm(false);
    } else {
      setViewMode(true);
    }
    if (outsideBuilderCall) {
      navigate(-1);
    }
  };

  const getDropdownOptions = (name) => {
    switch (name) {
      case ApprovalWorkFlowLib.Library: {
        return libraryDropDownOptions;
      }
      case ApprovalWorkFlowLib.ApproverType: {
        return approverTypeDropDownOptions;
      }
      case ApprovalWorkFlowLib.approverRoleID: {
        return approverRoleIdDropDownOptions;
      }
      case ApprovalWorkFlowLib.approverUserId: {
        return approverUserIdDropDownOptions;
      }
      case ApprovalWorkFlowLib.ModuleName: {
        return moduleNameDropDownOptions;
      }
      case ApprovalWorkFlowLib.formInputTaken: {
        return formInputDropDownOptions;
      }
      case ApprovalWorkFlowLib.sendBackStep: {
        return sendBackStepDropDownOptions;
      }
      case ApprovalWorkFlowLib.userBasedApprover: {
        return userBasedRole;
      }
      case ApprovalWorkFlowLib.reportingLevel: {
        return reportingLevels;
      }
      case ApprovalWorkFlowLib.FormName: {
        return formNamesDropDownOptions;
      }
      case ApprovalWorkFlowLib.fkConditionalCriteriaId: {
        return conditionalCriteria;
      }
      case ApprovalWorkFlowLib.rejectionEmailTemplate:
      case ApprovalWorkFlowLib.sendBackEmailTemplate:
      case ApprovalWorkFlowLib.finalApprovalEmailTemplate:
      case ApprovalWorkFlowLib.step0EmailTemplate:
      case ApprovalWorkFlowLib.lastStepReportingManagerEmailTemplate:
      case ApprovalWorkFlowLib.emailTemplate: {
        return emailTemplate;
      }
    }
  };

  const handleDropdownClick = (name) => {
    switch (name) {
      case ApprovalWorkFlowLib.Library: {
        return new approvalWorkflowServices()
          .getDropdownOptions(name)
          .then((res) => {
            setLibraryOptions(res);
            return true;
          });
      }
      case ApprovalWorkFlowLib.ApproverType: {
        return new approvalWorkflowServices()
          .getDropdownOptions(name)
          .then((res) => {
            setApproverTypeDropDownOptions(res);
            return true;
          });
      }
      case ApprovalWorkFlowLib.approverRoleID: {
        return new approvalWorkflowServices()
          .getDropdownOptions(name)
          .then((res) => {
            setApproverRoleIdDropDownOptions(res);
            return true;
          });
      }
      case ApprovalWorkFlowLib.approverUserId: {
        return new approvalWorkflowServices()
          .getDropdownOptions(name)
          .then((res) => {
            setApproverUserIdDropDownOptions(res);
            return true;
          });
      }
      case ApprovalWorkFlowLib.ModuleName: {
        return new approvalWorkflowServices()
          .getDropdownOptions(name)
          .then((res) => {
            setmoduleNameDropDownOptions(res);
            return true;
          });
      }
      case ApprovalWorkFlowLib.formInputTaken: {
        return new approvalWorkflowServices()
          .getDropdownOptions(name)
          .then((res) => {
            setformInputDropDownOptions(res);
            return true;
          });
      }
      case ApprovalWorkFlowLib.fkConditionalCriteriaId: {
        return new approvalWorkflowServices()
          .getDropdownOptions(name)
          .then((res) => {
            setConditionalCriteria(res);
            return true;
          });
      }
      case ApprovalWorkFlowLib.emailTemplate:
      case ApprovalWorkFlowLib.finalApprovalEmailTemplate:
      case ApprovalWorkFlowLib.sendBackEmailTemplate:
      case ApprovalWorkFlowLib.step0EmailTemplate:
      case ApprovalWorkFlowLib.lastStepReportingManagerEmailTemplate:
      case ApprovalWorkFlowLib.rejectionEmailTemplate: {
        return new approvalWorkflowServices()
          .getDropdownOptions(name)
          .then((res) => {
            setEmailTemplate(res);
            return true;
          });
      }
      case ApprovalWorkFlowLib.FormName: {
        return formNamesDropDownOptions;
      }
      case ApprovalWorkFlowLib.sendBackStep: {
        const stepOptions = [];
        for (let i = 0; i < steps.length; i++) {
          stepOptions.push({ id: i, name: i });
        }
        return setSendBackStepDropDownOptions(stepOptions);
      }
    }
  };

  useEffect(() => {
    // For Fetching the values up for the form names after on change on module name field
    handleDropdownClick(ApprovalWorkFlowLib.sendBackStep);
  }, [steps.length]);

  const handleChangeModuleType = useCallback(
    (event) => {
      const key = event.target.name;
      const value = event.target.value;
      new approvalWorkflowServices().getFormNames(value.id).then((res) => {
        setFormNamesOptions(res);
        outsideBuilderCall
          ? setApprovalLibFormState((prev) => {
              return { ...prev, [ApprovalWorkFlowLib.FormName]: null };
            })
          : setFormState((prev) => {
              return { ...prev, [ApprovalWorkFlowLib.FormName]: null };
            });
      });
      outsideBuilderCall
        ? setApprovalLibFormState((prev) => {
            return { ...prev, [key]: value };
          })
        : setFormState((prev) => {
            return { ...prev, [key]: value };
          });
    },
    [
      outsideBuilderCall
        ? approvalLibFormState?.[ApprovalWorkFlowLib.ModuleName]
        : formState?.[ApprovalWorkFlowLib.ModuleName],
    ]
  );

  const handleChangeLibrary = (event) => {
    const value = event.target.value;
    const key = event.target.name;
    dispatch(getApprovalWorkflowConfig(currentUserRoleId, value.id));
  };

  const handleChangeShowModal = useCallback((event) => {
    setShowModalEmailConfigs(true);
  }, []);

  const formFields = useMemo(() => {
    return formConfig.reduce((acc, item) => {
      const { fieldRefName } = item;
      let temp = { ...item };
      switch (fieldRefName) {
        case ApprovalWorkFlowLib.ModuleName: {
          temp = {
            ...temp,
            onChange: handleChangeModuleType,
            isHidden: outsideBuilderCall,
          };
          break;
        }
        case ApprovalWorkFlowLib.Library: {
          temp = {
            ...temp,
            onChange: handleChangeLibrary,
            isHidden: false,
          };
          break;
        }
        case ApprovalWorkFlowLib.FormName: {
          temp = { ...temp, isHidden: outsideBuilderCall };
          break;
        }
        case ApprovalWorkFlowLib.isStep0EmailTemplate:
        case ApprovalWorkFlowLib.isSendBackEmail:
        case ApprovalWorkFlowLib.isFinalApprovalEmail:
        case ApprovalWorkFlowLib.isRejectionEmail:
        case ApprovalWorkFlowLib.rejectionEmailTemplate:
        case ApprovalWorkFlowLib.sendBackEmailTemplate:
        case ApprovalWorkFlowLib.isStep0isdailyUpdateEmail:
        case ApprovalWorkFlowLib.finalApprovalEmailTemplate:
        case ApprovalWorkFlowLib.lastStepReportingManagerEmailTemplate:
        case ApprovalWorkFlowLib.isLastStepReportingManagerEmailTemplate:
        case ApprovalWorkFlowLib.isLastStepReportingManagerisdailyUpdateEmail:
        case ApprovalWorkFlowLib.step0EmailTemplate: {
          temp = { ...temp, isHidden: true };
          break;
        }
        case ApprovalWorkFlowLib.approvalEmailTemplates: {
          temp = { ...temp, onClick: handleChangeShowModal };
          break;
        }
        default: {
          break;
        }
      }
      if (!temp.isHidden) {
        acc.push(temp);
      }
      return acc;
    }, []);
  }, [
    formConfig,
    outsideBuilderCall
      ? approvalLibFormState?.[ApprovalWorkFlowLib.ModuleName]
      : formState?.[ApprovalWorkFlowLib.ModuleName],
  ]);

  const requiredFields = useMemo(() => {
    return getMandatoryFields(formFields);
  }, [formFields]);

  const approvalEmailConfigs = formConfig.filter((item) => {
    return (
      item.fieldRefName === ApprovalWorkFlowLib.isSendBackEmail ||
      item.fieldRefName === ApprovalWorkFlowLib.isFinalApprovalEmail ||
      item.fieldRefName === ApprovalWorkFlowLib.isRejectionEmail ||
      item.fieldRefName === ApprovalWorkFlowLib.sendBackEmailTemplate ||
      item.fieldRefName === ApprovalWorkFlowLib.rejectionEmailTemplate ||
      item.fieldRefName === ApprovalWorkFlowLib.finalApprovalEmailTemplate||
      item.fieldRefName === ApprovalWorkFlowLib.step0EmailTemplate ||
      item.fieldRefName === ApprovalWorkFlowLib.isStep0EmailTemplate||
      item.fieldRefName ===ApprovalWorkFlowLib.isStep0isdailyUpdateEmail || 
      item.fieldRefName === ApprovalWorkFlowLib.lastStepReportingManagerEmailTemplate ||
      item.fieldRefName === ApprovalWorkFlowLib.isLastStepReportingManagerEmailTemplate ||
      item.fieldRefName === ApprovalWorkFlowLib.isLastStepReportingManagerisdailyUpdateEmail
    );
  });

  const approvalEmailFormFields = useMemo(() => {
    return approvalEmailConfigs.reduce((acc, item) => {
      const { fieldRefName } = item;
      let temp = { ...item };
      switch (fieldRefName) {
        case ApprovalWorkFlowLib.isStep0EmailTemplate: {
          temp = { ...temp,fieldSize:!emailConfigsFormstate[ApprovalWorkFlowLib.isStep0EmailTemplate]?12:6};
          break;
        }
        case ApprovalWorkFlowLib.step0EmailTemplate: {
          temp = { ...temp, isHidden:!emailConfigsFormstate[ApprovalWorkFlowLib.isStep0EmailTemplate]};
          break;
        }
        case ApprovalWorkFlowLib.isSendBackEmail: {
          temp = { ...temp,fieldSize: !emailConfigsFormstate[ApprovalWorkFlowLib.isSendBackEmail]?12:6};
          break;
        }
        case ApprovalWorkFlowLib.isFinalApprovalEmail: {
          temp = { ...temp, fieldSize:!emailConfigsFormstate[ApprovalWorkFlowLib.isFinalApprovalEmail]?12:6};
          break;
        }
        case ApprovalWorkFlowLib.isRejectionEmail: {
          temp = { ...temp, fieldSize:!emailConfigsFormstate[ApprovalWorkFlowLib.isRejectionEmail]?12:6};
          break;
        }
        case ApprovalWorkFlowLib.rejectionEmailTemplate: {
          temp = { ...temp, isHidden:!emailConfigsFormstate[ApprovalWorkFlowLib.isRejectionEmail]};
          break;
        }
        case ApprovalWorkFlowLib.sendBackEmailTemplate: {
          temp = { ...temp, isHidden:!emailConfigsFormstate[ApprovalWorkFlowLib.isSendBackEmail]};
          break;
        }
        case ApprovalWorkFlowLib.finalApprovalEmailTemplate: {
          temp = { ...temp, isHidden:!emailConfigsFormstate[ApprovalWorkFlowLib.isFinalApprovalEmail] };
          break;
        }
        case ApprovalWorkFlowLib.isLastStepReportingManagerEmailTemplate: {
          temp = { ...temp, fieldSize:!emailConfigsFormstate[ApprovalWorkFlowLib.isLastStepReportingManagerEmailTemplate]?12:6};
          break;
        }
        case ApprovalWorkFlowLib.lastStepReportingManagerEmailTemplate: {
          temp = { ...temp, isHidden:!emailConfigsFormstate[ApprovalWorkFlowLib.isLastStepReportingManagerEmailTemplate]};
          break;
        }
        default: {
          break;
        }
      }
      if (!temp.isHidden) {
        acc.push(temp);
      }
      if (temp.isHidden) {
        emailConfigsFormstate[temp?.fieldRefName]=null;
      }
      return acc;
    }, []);
  }, [approvalEmailConfigs,outsideBuilderCall ? approvalLibFormState : formState]);

  const handleDraft = () => {
    dispatch(
      postApprovalWorkflowInfo(
        currentUserRoleId,
        formState,
        approvalWorkflowFormDataSteps,
        'draft',
        formId,
        stepsDeletedForm
      )
    )
      .then((res) => {
        showToast(
          ToastMessages.success.replace(stringSubstitute, toastMessage.success),
          ToastThemes.success
        );
        dispatch(getApprovalWorkflowConfig(currentUserRoleId, res.id));
        setViewMode(true);
      })
      .catch((err) => {
        showToast(err, ToastThemes.error);
      });
  };

  const handleSave = () => {
    const errors = getValidationErrors(formState, requiredFields);
    const stepErrors = [];
    for (let i = 0; i < steps.length; i++) {
      if (approvalWorkflowFormDataSteps[i] == undefined) {
        stepErrors.push(getValidationErrors({}, getMandatoryFields(steps[i])));
      } else {
        stepErrors.push(
          getValidationErrors(
            approvalWorkflowFormDataSteps[i],
            getMandatoryFields(steps[i])
          )
        );
      }
    }
    const hasNonEmptyObject = getNonEmptyObject(stepErrors);
    if (isEmpty(errors) && !hasNonEmptyObject) {
      dispatch(
        postApprovalWorkflowInfo(
          currentUserRoleId,
          formState,
          approvalWorkflowFormDataSteps,
          'save',
          formId,
          stepsDeletedForm
        )
      )
        .then((res) => {
          showToast(
            ToastMessages.success.replace(
              stringSubstitute,
              toastMessage.success
            ),
            ToastThemes.success
          );
          setViewMode(true);
          dispatch(getApprovalWorkflowConfig(currentUserRoleId, res.id));
        })
        .catch((err) => {
          showToast(err, ToastThemes.error);
        });
      outsideBuilderCall
        ? setApprovalErrorsLib(errors)
        : setValidationErrorsLib(errors);
      outsideBuilderCall
        ? setApprovalErrorsSteps(stepErrors)
        : setformStepsValidationErrors(stepErrors);
    } else {
      outsideBuilderCall
        ? setApprovalErrorsLib(errors)
        : setValidationErrorsLib(errors);
      outsideBuilderCall
        ? setApprovalErrorsSteps(stepErrors)
        : setformStepsValidationErrors(stepErrors);
    }
  };
  return (
    <>
      <Box>
        <Box mb={3} mx={-3} mt={-3}>
          <ActionHeader
            labelText={
              outsideBuilderCall
                ? approvalLibFormState?.[ApprovalWorkFlowLib.Name]
                : formState?.[ApprovalWorkFlowLib.Name] ||
                  'New Approval Workflow'
            }
            editButtonText="Draft"
            showDiscard
            showPublish
            showPublishModal={false}
            showSave={
              outsideBuilderCall
                ? false
                : approvalWorkflowFormDataLib?.isPublished && formId
                ? false
                : true
            }
            onClickDiscard={handleDiscard}
            onPublishClick={outsideBuilderCall ? outsideSave : handleSave}
            onEditClick={handleDraft}
            publishButtonText="Save"
          />
        </Box>
        <Grid container rowSpacing={2} columnSpacing={2}>
          <Grid item md={11}>
            <Paper sx={{ p: 3 }}>
              <FormEdit
                formInfo={outsideBuilderCall ? approvalLibFormState : formState}
                list={formFields}
                handleDropdownClick={handleDropdownClick}
                getDropdownOptions={getDropdownOptions}
                setFormData={
                  outsideBuilderCall ? setApprovalLibFormState : setFormState
                }
                errors={
                  outsideBuilderCall ? approvalErrorsLib : validationErrorsLib
                }
                setErrors={
                  outsideBuilderCall
                    ? setApprovalErrorsLib
                    : setValidationErrorsLib
                }
                hideFields={false}
              />
              <Box mt={3} mr={7}>
                <Grid container rowSpacing={2} columnSpacing={2}>
                  <ApprovalWorkflowSteps
                    steps={steps}
                    handleDropdownClick={handleDropdownClick}
                    getDropdownOptions={getDropdownOptions}
                    formInfo={approvalWorkflowFormDataSteps}
                    formStepsValidationErrors={
                      outsideBuilderCall
                        ? approvalErrorsSteps
                        : formStepsValidationErrors
                    }
                    setformStepsValidationErrors={
                      outsideBuilderCall
                        ? setApprovalErrorsSteps
                        : setformStepsValidationErrors
                    }
                    formId={formId}
                  />
                </Grid>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Box>
      <ConditionalModal
        showModal={showModalEmailConfigs}
        secondaryAction="close"
        title="Email Configurations &nbsp; &nbsp;&nbsp; &nbsp;&nbsp;"
        setshowModal={setShowModalEmailConfigs}
        listComponent={
          <>
          <FormEdit
            list={approvalEmailFormFields}
            getDropdownOptions={getDropdownOptions}
            handleDropdownClick={handleDropdownClick}
            setFormData={setEmailConfigsFormstate}
            formInfo={emailConfigsFormstate}
            padding="0"
            hideFields={false}
          />
          <Typography variant="caption" color={'neutral.dark80'}>
            Note: Step 0 is top level step who creates the record.
          </Typography>
          </>
        }
      />
    </>
  );
};

export default AppWorkEditForm;
