import { FormFieldTypes } from '../../../constants/common';
import { preventKeyPress } from '../../../utils/common.util';
import { preventKeyPressWithoutDecimal } from '../../forms/claim-form-render/add-form-data/unergizer-claim-form/utils/unergizer-utils';

export const CompBenTabs = [
  {
    id: 'benefits',
    name: 'Benefits',
    module: 'comp-ben'
  },
  {
    id: 'ben-dashboard',
    name: 'Benefits Dashboard',
    module: 'ben-dashboard'
  },
];

export const BenefitFormConfig = [
  {
    id: 1,
    fieldLabel: 'Name',
    placeholderText: 'Type Here',
    fieldType: FormFieldTypes.Text,
    fieldRefName: 'name',
    size: 'small',
    fullWidth: true,
    isRequired: true,
  },
  {
    id: 3,
    fieldLabel: 'Allow First Time Deduction',
    fieldType: FormFieldTypes.CheckBox,
    fieldRefName: 'allowCopayment',
  },
  {
    id: 2,
    fieldLabel: 'Deduction Amount',
    placeholderText: 'Type Here',
    fieldType: FormFieldTypes.Text,
    dataType: FormFieldTypes.Number,
    fieldRefName: 'coPaymentAmount',
    size: 'small',
    fullWidth: true,
    onKeyDown: preventKeyPress,
    percisionNumber: 2,
    isHidden: true,
  },
  {
    fieldLabel: 'Link Salary Component',
    placeholderText: 'Select Component',
    fieldType: FormFieldTypes.SingleSelect,
    isRequired: false,
    fieldRefName: 'fkLinkedPayStructureComponentId',
  },
  {
    fieldLabel: 'Pro Rate Condition',
    placeholderText: 'Select Condition',
    fieldType: FormFieldTypes.SingleSelect,
    isRequired: false,
    fieldRefName: 'prorateMethod',
  },
  {
    fieldLabel: 'Pro Rate Meta Data',
    placeholderText: 'Select Condition',
    fieldType: FormFieldTypes.MutliSelect,
    isRequired: false,
    fieldRefName: 'prorateMethodOptions',
  },
  {
    fieldLabel: 'Is Multi Department',
    fieldType: FormFieldTypes.CheckBox,
    isRequired: false,
    fieldRefName: 'isMultiDepartment',
  },
  {
    fieldLabel: 'Is Fixed Entitlement',
    fieldType: FormFieldTypes.CheckBox,
    isRequired: false,
    fieldRefName: 'isFixedEntitlement',
  },
  {
    fieldLabel: 'Without Block',
    fieldType: FormFieldTypes.CheckBox,
    isRequired: false,
    fieldRefName: 'withoutBlock',
  },
  {
    fieldLabel: 'Consider Part Time Factor',
    fieldType: FormFieldTypes.CheckBox,
    isRequired: false,
    fieldRefName: 'considerPartTimeFactor',
    fieldSize: 12
  },
  {
    fieldLabel: 'Creator Role',
    placeholderText: 'Select Role',
    fieldType: FormFieldTypes.SingleSelect,
    isRequired: true,
    fieldRefName: 'autoCreatorRole',
  },
  {
    fieldLabel: 'Creator User',
    placeholderText: 'Select User in Role',
    fieldType: FormFieldTypes.SingleSelect,
    isRequired: true,
    fieldRefName: 'autoCreator',
  },
  {
    id: 2,
    fieldLabel: 'Grace Period (in days)',
    placeholderText: 'Type Here',
    fieldType: FormFieldTypes.Text,
    dataType: FormFieldTypes.Number,
    fieldRefName: 'gracePeriod',
    size: 'small',
    fullWidth: true,
    onKeyDown: preventKeyPressWithoutDecimal,
    range: {
      'max': 90,
      'min': 0,
      'maxType': 'isLessThanAndEqual',
      'minType': 'isGreaterThanAndEqual'
    }
  },
];

export const conditionInitialVal = {
  sysField: '',
  value: '',
  operator: '',
};
export const getConditionFormFields = (onChangeFn) => {
  return [
    {
      id: 1,
      fieldLabel: 'When:',
      placeholderText: 'Select',
      fieldType: FormFieldTypes.SingleSelect,
      isRequired: false,
      fieldRefName: 'sysField',
      onChange: onChangeFn[ConditionFormFieldsName.Component],
      size: 'small',
      fullWidth: true,
      fieldSize: 4,
    },
    {
      id: 1,
      fieldLabel: 'Is:',
      placeholderText: 'Select',
      fieldType: FormFieldTypes.SingleSelect,
      isRequired: false,
      fieldRefName: 'operator',
      onChange: onChangeFn[ConditionFormFieldsName.Operator],
      size: 'small',
      fullWidth: true,
      fieldSize: 4,
    },
    {
      id: 1,
      fieldLabel: 'Value:',
      placeholderText: 'Type',
      fieldType: FormFieldTypes.Text,
      isRequired: false,
      fieldRefName: 'value',
      size: 'small',
      fullWidth: true,
      fieldSize: 4,
    },
  ];
};

export const ConditionFormFieldsName = {
  Component: 'sysField',
  Operator: 'operator',
  Value: 'value',
};

export const BenefitDistributionHeader = [
  {
    id: 'name',
    label: 'Employee Name',
  },
  {
    id: 'start',
    label: 'Start Date',
  },
  {
    id: 'end',
    label: 'End Date',
  },
  {
    id: 'entitlementAmt',
    label: 'Entitlement Amount',
    numeric: true,
  },
  {
    id: 'entitlementBalance',
    label: 'Entitlement Balance',
    numeric: true,
  },
  {
    id: 'entitlementAmtUsed',
    label: 'Entitlement Amount Used',
    numeric: true,
  },
  {
    id: 'deptName',
    label: 'Department',
  },
];

export const UserBenefitHeader = [
  {
    id: 'name',
    label: 'Employee Name',
  },
  {
    id: 'start',
    label: 'Start Date',
  },
  {
    id: 'end',
    label: 'End Date',
  },
  {
    id: 'entitlementAmt',
    label: 'Entitlement Amount',
    numeric: true,
  },
  {
    id: 'entitlementBalance',
    label: 'Entitlement Balance',
    numeric: true,
  },
  {
    id: 'status',
    label: 'Status',
  },
];
