import { Box } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import TabBar from '../../../atoms/Tabs/TabBar';
import { useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import AddForm from './add-form.component';
import AppWorkStr from '../../OnBoarding/Organization/ApprovalFlow/ApprovalFlowForm/AddAppWork';
import { useDispatch, useSelector } from 'react-redux';
import {
  APIRequestStatus,
  LoadingStatus,
  ToastThemes,
  toastMessage,
} from '../../../constants/common';
import {
  getClaimFormConfig,
  postConfigInfo,
  resetConfig,
} from '../../../redux/form-builder/action';
import {
  getApprovalWorkflowConfig,
  postApprovalWorkflowInfo,
  resetApprovalWorkflowStructure,
} from '../../../redux/approvalFlow/action';
import { showToast } from '../../../utils/common.util';
import {
  ApprovalWorkFlowLib,
  ToastMessages,
  stringSubstitute,
} from '../../OnBoarding/Organization/constants/onboarding.constants';
import {
  getMandatoryFields,
  getValidationErrors,
} from '../../OnBoarding/Organization/utils/userOnboarding/userOnboarding.util';
import { getNonEmptyObject } from '../../OnBoarding/Organization/utils/onboarding.util';
import { isEmpty } from 'lodash';
import CircularLoader from '../../../atoms/CircularLoader/circular-loader';
import FormAccessMultipleConditional from '../modal-component/form-access-conditional/multiple-condiation-formacess.component';

const ManageFormTab = () => {
  const [viewMode, setViewMode] = useOutletContext();
  const [formview, setFormView] = useState(false);
  const [strucutreId, setStructureId] = useState('');
  const [approvalErrorsLib, setApprovalErrorsLib] = useState({});
  const [approvalErrorsSteps, setApprovalErrorsSteps] = useState([]);
  const { currentUserRoleId } = useSelector((state) => state.main);
  const { claimData, list, requestProcessing } =
    useSelector((state) => state.formBuilder) || {};
  const [loading, setLoading] = useState(-1);
  const [postLoading, setPostLoading] = useState(-1);
  const { [APIRequestStatus.GET_CLAIM_FORM_CONFIG]: detailsLoadingStatus } =
    requestProcessing || {};
  const {
    approvalWorkflowConfigLib,
    approvalWorkflowConfigSteps,
    approvalWorkflowFormDataLib,
    approvalWorkflowFormDataSteps,
    approvalWorkflowFormDataStepsDeleted,
    requestProcessing: {
      [APIRequestStatus.GET_APPROVAL_WORKFLOW_STRUCTURE_CONFIG]:
        configLoadingStatus,
    },
  } = useSelector((state) => state.approvalWorkflow);
  const dispatch = useDispatch();
  const location = useLocation();
  const pathNames = location.pathname.split('/');
  const idForFormDetails = pathNames[pathNames.length - 1];
  const [builderFormInfo, setBuilderFormInfo] = useState(claimData || {});
  const [approvalLibFormState, setApprovalLibFormState] = useState(
    approvalWorkflowFormDataLib
  );
  const [errorTabNames, setErrorTabNames] = useState([]);
  const navigate = useNavigate();
  const [currentTab, setCurrentTab] = useState(0);
  const onSearchChange = (e, searchTab) => {
    const index = formTab.findIndex((tab) => tab.name === searchTab.name);
    if (index !== -1) setCurrentTab(index);
  };

  useEffect(() => {
    setBuilderFormInfo(claimData);
  }, [claimData]);

  useEffect(() => {
    if (currentUserRoleId) {
      if (!isNaN(idForFormDetails)) {
        setLoading(LoadingStatus.Loading);
        dispatch(getClaimFormConfig(idForFormDetails))
          .then((res) => {
            if (res?.approvalWorkflowId) {
              setStructureId(res?.approvalWorkflowId);
              dispatch(
                getApprovalWorkflowConfig(
                  currentUserRoleId,
                  res?.approvalWorkflowId
                )
              );
            }
            setLoading(LoadingStatus.Success);
          })
          .catch(() => {
            setLoading(LoadingStatus.Failure);
          });
      } else {
        dispatch(resetConfig());
        dispatch(getApprovalWorkflowConfig(currentUserRoleId));
      }
    }
  }, [idForFormDetails, currentUserRoleId]);

  useEffect(() => {
    setApprovalLibFormState(approvalWorkflowFormDataLib);
  }, [approvalWorkflowFormDataLib]);

  const formFields = useMemo(() => {
    return approvalWorkflowConfigLib.reduce((acc, item) => {
      const { fieldRefName } = item;
      let temp = { ...item };
      switch (fieldRefName) {
        case ApprovalWorkFlowLib.ModuleName: {
          temp = {
            ...temp,
            isHidden: true,
          };
          break;
        }
        case ApprovalWorkFlowLib.Library: {
          temp = {
            ...temp,
            isHidden: false,
          };
          break;
        }
        case ApprovalWorkFlowLib.FormName: {
          temp = { ...temp, isHidden: true };
          break;
        }
        default: {
          break;
        }
      }
      if (!temp.isHidden) {
        acc.push(temp);
      }
      return acc;
    }, []);
  }, [approvalWorkflowConfigLib]);

  const requiredFields = useMemo(() => {
    return getMandatoryFields(formFields);
  }, [formFields]);

  const handleFormSaveClick = () => {
    setErrorTabNames([]);
    const errors = getValidationErrors(approvalLibFormState, requiredFields);
    const stepErrors = [];
    for (let i = 0; i < approvalWorkflowConfigSteps.length; i++) {
      if (approvalWorkflowFormDataSteps[i] == undefined) {
        stepErrors.push(
          getValidationErrors(
            {},
            getMandatoryFields(approvalWorkflowConfigSteps[i])
          )
        );
      } else {
        stepErrors.push(
          getValidationErrors(
            approvalWorkflowFormDataSteps[i],
            getMandatoryFields(approvalWorkflowConfigSteps[i])
          )
        );
      }
    }
    const hasNonEmptyObject = getNonEmptyObject(stepErrors);
    if (isEmpty(errors) && !hasNonEmptyObject) {
      setPostLoading(LoadingStatus.Loading);
      dispatch(postConfigInfo(builderFormInfo, list))
        .then((res) => {
          dispatch(
            postApprovalWorkflowInfo(
              currentUserRoleId,
              { ...approvalLibFormState, fkAwfFormId: res?.fkModuleId },
              approvalWorkflowFormDataSteps,
              'save',
              strucutreId,
              approvalWorkflowFormDataStepsDeleted
            )
          )
            .then(() => {
              res?.claimTypeId
                ? navigate(`/builder/${res.claimTypeId}`, { replace: true })
                : dispatch(getClaimFormConfig(idForFormDetails));
              showToast(
                ToastMessages.success.replace(
                  stringSubstitute,
                  toastMessage.success
                ),
                ToastThemes.success
              );
              setPostLoading(LoadingStatus.Success);
            })
            .catch((err) => {
              showToast(err, ToastThemes.error);
              setPostLoading(LoadingStatus.Failure);
              setBuilderFormInfo((claimData)=>{
                claimData.fkModuleId = {id:res?.fkModuleId};
                return claimData;
              });
            });
        })
        .catch((err) => {
          showToast(err, ToastThemes.error);
          setPostLoading(LoadingStatus.Failure);
        });
      setApprovalErrorsLib(errors);
      setApprovalErrorsSteps(stepErrors);
    } else {
      setCurrentTab(1);
      setErrorTabNames(['approval-form']);
      showToast('Fill all required fields', ToastThemes.error);
      setApprovalErrorsLib(errors);
      setApprovalErrorsSteps(stepErrors);
    }
  };
  const formTab = [
    {
      name: 'create-form',
      label: 'Manage Form',
      title: 'Manage Form',
      hide: false,
      panel: (
        <AddForm
          viewMode={viewMode}
          setViewMode={setViewMode}
          loading={loading}
          list={list}
          claimData={claimData}
          idForFormDetails={idForFormDetails}
          formInfo={builderFormInfo}
          setFormInfo={setBuilderFormInfo}
          handleFormSaveClick={handleFormSaveClick}
        />
      ),
    },
    {
      name: 'approval-form',
      label: 'Approval Workflow',
      title: 'Approval Workflow',
      hide: false,
      panel: (
        <AppWorkStr
          viewMode={formview}
          strucutreId={strucutreId}
          setViewMode={setFormView}
          setShowForm={() => {}}
          outsideBuilderCall={true}
          approvalLibFormState={approvalLibFormState}
          setApprovalLibFormState={setApprovalLibFormState}
          outsideSave={handleFormSaveClick}
          approvalErrorsLib={approvalErrorsLib}
          setApprovalErrorsLib={setApprovalErrorsLib}
          approvalErrorsSteps={approvalErrorsSteps}
          setApprovalErrorsSteps={setApprovalErrorsSteps}
          outsideBuilderData={{
            approvalWorkflowConfigLib,
            approvalWorkflowConfigSteps,
            approvalWorkflowFormDataLib,
            approvalWorkflowFormDataSteps,
            approvalWorkflowFormDataStepsDeleted,
          }}
        />
      ),
    },
    {
      name: 'create-form-condition',
      label: 'Form Access Condition',
      title: 'Form Access Condition',
      hide: false,
      panel: (
        <FormAccessMultipleConditional
          setFormInfo={setBuilderFormInfo}
          conditional={builderFormInfo.conditional}
        />
      ),
    },
  ];

  return (
    <Box>
      <CircularLoader
        show={
          loading === LoadingStatus.Loading ||
          postLoading === LoadingStatus.Loading
        }
      />
      <TabBar
        tabPanels={formTab}
        searchTabs
        disableCreate
        errorTabNames={errorTabNames}
        onSearchChange={onSearchChange}
        currentTab={currentTab}
      />
    </Box>
  );
};

export default ManageFormTab;
