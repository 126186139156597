import {
  Box,
  Grid,
  Paper,
  Radio,
  FormControlLabel,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import FieldView from '../../../molecules/FieldView/FieldView';
import { entitlementsConfigs } from '../../../form-builder/utils/entitlement.util';
import { useDispatch, useSelector } from 'react-redux';
import { entitlementId } from '../../../../redux/form-builder/action';
import { isEmpty } from 'lodash';

const EntitlementsView = (props) => {
  const { entitlements = [] } = props;
  const [selectedEntitlement, setSelectedEntitlement] = useState(null);
  const { entitlement } = useSelector((state) => state.formBuilder);
  const currencySymbol = JSON.parse(
    localStorage.getItem('orgInfo')
  )?.currencySymbol;
  const dispatch = useDispatch();
  const handleSelectEntitlement = (entitlement) => {
    setSelectedEntitlement(entitlement.id);
    dispatch(entitlementId(entitlement));
  };

  useEffect(() => {
    if (
      selectedEntitlement === null &&
      entitlements[0]?.id &&
      isEmpty(entitlement)
    ) {
      setSelectedEntitlement(entitlements[0].id);
      dispatch(entitlementId(entitlements[0]));
    } else {
      if (!isEmpty(entitlement)) {
        const ent = entitlements.find((item) => item.id === entitlement.id);
        if (ent) {
          setSelectedEntitlement(ent?.id);
          dispatch(entitlementId(ent || {}));
        } else {
          setSelectedEntitlement(entitlements[0].id);
          dispatch(entitlementId(entitlements[0]));
        }
      }
    }
  }, [entitlements]);

  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ flexGrow: 1, p: 4, my: 1 }}>
        {entitlements.map((entitlement, index) => {
          const {
            id = '',
            startDate = '',
            endDate = '',
            entitlementAmt = 0,
            entitlementBalanceAmt = 0,
          } = entitlement;

          return (
            <Grid
              container
              rowSpacing={3}
              columnSpacing={12}
              pr={8}
              alignItems="center"
              key={id}
              mt={index !== 0 ? 2 : 'none'}
            >
              <Grid item md={1.5}>
                <FormControlLabel
                  control={
                    <Radio
                      checked={selectedEntitlement === id}
                      onChange={() => handleSelectEntitlement(entitlement)}
                      value={selectedEntitlement}
                      name="entitlement-radio"
                      color="primary"
                    />
                  }
                />
              </Grid>
              {entitlementsConfigs
                .filter(
                  (config) =>
                    !config.hide({
                      startDate,
                      endDate,
                      entitlementAmt,
                      entitlementBalanceAmt,
                    })
                )
                .map((data) => (
                  <Grid item md={data.fieldSize} key={data.label}>
                    <FieldView
                      labelText={data.label}
                      labelValue={data.getter(
                        {
                          startDate,
                          endDate,
                          entitlementAmt,
                          entitlementBalanceAmt,
                        },
                        currencySymbol
                      )}
                      required={data.required}
                    />
                  </Grid>
                ))}
            </Grid>
          );
        })}
      </Paper>
    </Box>
  );
};

export default EntitlementsView;
