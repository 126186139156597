import React, { useEffect, useState } from 'react';
import ClaimEditForm from './Claim-form/claim-form-edit.component';
import ClaimViewForm from './Claim-form/claim-form-view.component';
import { useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import {
  entitlementId,
  getClaimForm,
  resetConfig,
} from '../../../../redux/form-builder/action';
import { APIRequestStatus, LoadingStatus } from '../../../../constants/common';
import CircularLoader from '../../../../atoms/CircularLoader/circular-loader';
import TryAgain from '../../../molecules/ErrorState/error.component';

const AddClaimForm = (props) => {
  const {
    viewMode,
    setViewMode,
    enitilements,
    setClaimHeaderData,
    departementFomdata,
    setTeamBuildingData,
    benefitId,
    isMultiDepartment,
    setDepartementFomdata,
    setDepartementDropDown,
    setBenefits,
    unergizerFormData,
    setUnergizerFormData,
    allowCopayment,
    singleDeptAllowed
  } = props;
  const dispatch = useDispatch();
  const location = useLocation();
  const paths = location.pathname.split('/');
  const claimTypeId = Number(paths[paths.length - 2]);
  const claimId = Number(paths[paths.length - 1]);
  const [isEditable, setIsEditable] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const {
    list,
    claimData,
    claimFormData = {},
    auditTrail = [],
    allowEdit = false,
    permissions,
    requestProcessing,
    approvalRemarksPermission
  } = useSelector((state) => state.formBuilder);
  const { currentUserRoleId } = useSelector((state) => state.main);

  const { [APIRequestStatus.GET_CLAIMS_DETAILS]: configLoadingStatus } =
    requestProcessing || {};

  useEffect(() => {
    if (currentUserRoleId) {
      if (!isNaN(claimId)) {
        dispatch(getClaimForm(claimTypeId, currentUserRoleId, claimId));
        setViewMode(true);
      } else {
        dispatch(getClaimForm(claimTypeId, currentUserRoleId));
      }
    }
    return () => {
      setViewMode(false);
      dispatch(resetConfig());
    };
  }, [claimTypeId, currentUserRoleId, claimId]);

  useEffect(() => {
    setClaimHeaderData(claimFormData?.createdBy);
    dispatch(entitlementId(claimFormData?.entitlement || {}));
  }, [claimFormData]);

  useEffect(() => {
    if (isMultiDepartment) {
      setTeamBuildingData(() => {
        return {
          attendees: claimFormData?.attendees?.map((item) => item.id),
          receiptAmount: claimFormData?.receiptAmount,
        };
      });
    }
  }, [claimFormData?.attendees, claimFormData?.receiptAmount]);

  if (configLoadingStatus === LoadingStatus.Failure) {
    return <TryAgain />;
  }

  return (
    <>
      <CircularLoader show={configLoadingStatus === LoadingStatus.Loading} />
      {configLoadingStatus === LoadingStatus.Success && viewMode ? (
        <ClaimViewForm
          claimTypeId={claimTypeId}
          claimId={claimId}
          claimFormData={claimFormData}
          formConfig={list}
          setViewMode={setViewMode}
          claimName={claimData.name}
          moduleId={claimData?.fkModuleId?.id}
          auditTrail={auditTrail}
          allowEdit={allowEdit}
          isEditable={isEditable}
          setIsEditable={setIsEditable}
          permissions={permissions}
          setValidationErrors={setValidationErrors}
          isMultiDepartment={isMultiDepartment}
          benefitId={benefitId}
          paymentModeId={claimData?.paymentMode?.paymentOptionCode}
          approvalRemarksPermission={approvalRemarksPermission}
        />
      ) : (
        <ClaimEditForm
          formConfig={list}
          claimTypeData={claimData}
          claimFormData={claimFormData}
          setViewMode={setViewMode}
          claimTypeId={claimTypeId}
          claimId={claimId}
          resubmittedClaim={isEditable && claimFormData?.status !== 'DRAFT'}
          allowEdit={allowEdit}
          enitilements={enitilements}
          validationErrors={validationErrors}
          setValidationErrors={setValidationErrors}
          departementFomdata={departementFomdata}
          setTeamBuildingData={setTeamBuildingData}
          benefitId={benefitId}
          isMultiDepartment={isMultiDepartment}
          setDepartementFomdata={setDepartementFomdata}
          setDepartementDropDown={setDepartementDropDown}
          setBenefits={setBenefits}
          setUnergizerFormData={setUnergizerFormData}
          unergizerFormData={unergizerFormData}
          isUnergizer={claimData?.isUnergizer}
          allowCopayment={allowCopayment}
          singleDeptAllowed={singleDeptAllowed}
        />
      )}
    </>
  );
};

export default AddClaimForm;
