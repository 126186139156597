import { Button } from '@mui/material';
import React from 'react';

import './ButtonUI.css';

const ButtonUI = (props) => {
  return (
    <React.Fragment>
      <Button
        variant={props.variant ? props.variant : 'contained'}
        style={{
          backgroundColor: props.backgroundColor
            ? props.backgroundColor
            : 'transparent',
          color: props.color ? props.color : '#000000',
          border: props.border && props.border,
          width: props.width && props.width,
          padding: props.padding && props.padding,
          margin: props.margin,
          textTransform: 'capitalize',
          fontWeight: '600',
          fontSize: props.fontSize && props.fontSize,
          boxShadow: 'none',
          borderRadius: '6px !important',
          cursor: 'pointer !important',
        }}
        size={props.size ? props.size : 'medium'}
        onClick={props.onclick}
        className={props.className}
        disabled={props.disabled ? props.disabled : false}
      >
        {props.children}
      </Button>
    </React.Fragment>
  );
};
export default ButtonUI;
