import { APIRequestStatus, LoadingStatus } from '../../constants/common';
import {
  ADD_CONFIG,
  DELETE_MODAL,
  EDIT_MODAL_DATA,
  GET_ALL_FORMS_LIST_FAILURE,
  GET_ALL_FORMS_LIST_REQUEST,
  GET_ALL_FORMS_LIST_SUCCESS,
  GET_CLAIMS_DETAILS_FAILURE,
  GET_CLAIMS_DETAILS_REQUEST,
  GET_CLAIMS_DETAILS_SUCCESS,
  GET_CLAIM_FORM_CONFIG_FAILURE,
  GET_CLAIM_FORM_CONFIG_REQUEST,
  GET_CLAIM_FORM_CONFIG_SUCCESS,
  GET_MULTI_TRF_CLAIMS_DETAILS_FAILURE,
  GET_MULTI_TRF_CLAIMS_DETAILS_REQUEST,
  GET_MULTI_TRF_CLAIMS_DETAILS_SUCCESS,
  POST_CLAIM_INFO_FAILURE,
  POST_CLAIM_INFO_REQUEST,
  POST_CLAIM_INFO_SUCCESS,
  POST_CONFIG_INFO_FAILURE,
  POST_CONFIG_INFO_REQUEST,
  POST_CONFIG_INFO_SUCCESS,
  RESET_CONFIG,
  SELECT_ENTITLEMENT,
} from './actionTypes';

const initialState = {
  requestProcessing: {
    [APIRequestStatus.POST_CONFIG_INFO]: LoadingStatus.Loading,
    [APIRequestStatus.GET_CLAIM_FORM_CONFIG]: LoadingStatus.Loading,
    [APIRequestStatus.GET_ALL_FORMS_LIST]: LoadingStatus.Loading,
    [APIRequestStatus.POST_CLAIM_INFO]: LoadingStatus.Loading,
    [APIRequestStatus.GET_CLAIMS_DETAILS]: LoadingStatus.Loading,
    [APIRequestStatus.GET_MULTI_TRF_CLAIMS_DETAILS]: LoadingStatus.Loading,
  },
  list: [],
  claimData: {},
  allDashboardList: [],
  claimFormData: {},
  auditTrail: [],
  allowEdit: false,
  permissions: {},
  approvalRemarksPermission: {},
  trfClaimData: [],
  trfClaimConfig: [],
  trfClaimType: {},
  entitlement: {}
};

export const formBuilderReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_CONFIG: {
      const formConfig = action.payload;
      return {
        ...state,
        list: sortedArray([...state.list, formConfig]),
      };
    }

    case EDIT_MODAL_DATA: {
      const { index, data } = action.payload;
      const newList = [...state.list];
      newList[index] = { ...data };
      return {
        ...state,
        list: sortedArray(newList),
      };
    }

    case DELETE_MODAL: {
      const index = action.payload;
      const newList = [...state.list];
      newList.splice(index, 1);
      return {
        ...state,
        list: sortedArray(newList),
      };
    }

    case POST_CONFIG_INFO_REQUEST: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.POST_CONFIG_INFO]: LoadingStatus.Loading,
        },
      };
    }

    case POST_CONFIG_INFO_SUCCESS: {
      const { claimBody, claimConfig } = action.payload;
      return {
        ...state,
        list: claimConfig,
        claimData: claimBody,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.POST_CONFIG_INFO]: LoadingStatus.Success,
        },
      };
    }

    case POST_CONFIG_INFO_FAILURE: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.POST_CONFIG_INFO]: LoadingStatus.Failure,
        },
      };
    }

    case GET_CLAIM_FORM_CONFIG_REQUEST: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_CLAIM_FORM_CONFIG]: LoadingStatus.Loading,
        },
      };
    }

    case GET_CLAIM_FORM_CONFIG_SUCCESS: {
      const { claim, claimConfig } = action.payload;
      const newClaimConfig = claimConfig.map((item) => {
        if (item.minMinutesNextClaim) {
          item.errorMessage = item.minMinutesNextClaim.errorMessage || null;
          item.timeInMinutes = item.minMinutesNextClaim.timeInMinutes || null;
        }
        if (item.validationOption) {
          item.errorMessageValidationOptions =
            item.validationOption.errorMessageValidationOptions || null;
          item.option = item.validationOption.option || null;
        }
        delete item.minMinutesNextClaim;
        delete item.validationOption;
        return item;
      });
      const newClaim = {
        ...claim,
        allowCopayment: claim?.fkBenefitId?.allowCopayment,
        fkBenefitId: {
          id: claim?.fkBenefitId?.id,
          name: claim?.fkBenefitId?.name,
        },
      };
      return {
        ...state,
        list: newClaimConfig,
        claimData: newClaim,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_CLAIM_FORM_CONFIG]: LoadingStatus.Success,
        },
      };
    }

    case GET_CLAIM_FORM_CONFIG_FAILURE: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_CLAIM_FORM_CONFIG]: LoadingStatus.Failure,
        },
      };
    }

    case GET_ALL_FORMS_LIST_REQUEST: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_ALL_FORMS_LIST]: LoadingStatus.Loading,
        },
      };
    }

    case GET_ALL_FORMS_LIST_SUCCESS: {
      const res = action.payload;
      return {
        ...state,
        allDashboardList: res,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_ALL_FORMS_LIST]: LoadingStatus.Success,
        },
      };
    }

    case GET_ALL_FORMS_LIST_FAILURE: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_ALL_FORMS_LIST]: LoadingStatus.Failure,
        },
      };
    }

    case RESET_CONFIG: {
      return initialState;
    }

    case POST_CLAIM_INFO_REQUEST: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.POST_CLAIM_INFO]: LoadingStatus.Loading,
        },
      };
    }

    case POST_CLAIM_INFO_SUCCESS: {
      const { claim, claimData, id } = action.payload;
      id && Object.assign(claimData, { id });
      return {
        ...state,
        claimFormData: claimData,
        claimData: claim,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.POST_CLAIM_INFO]: LoadingStatus.Success,
        },
      };
    }

    case POST_CLAIM_INFO_FAILURE: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.POST_CLAIM_INFO]: LoadingStatus.Failure,
        },
      };
    }

    case GET_CLAIMS_DETAILS_REQUEST: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_CLAIMS_DETAILS]: LoadingStatus.Loading,
        },
      };
    }

    case GET_CLAIMS_DETAILS_SUCCESS: {
      const {
        claim,
        claimConfig,
        auditTrail,
        allowEdit,
        permissions,
        approvalRemarksPermission,
      } = action.payload;
      return {
        ...state,
        claimFormData: claim,
        list: claimConfig?.claimConfig,
        claimData: claimConfig?.claim,
        auditTrail: auditTrail,
        allowEdit: allowEdit,
        permissions: permissions,
        approvalRemarksPermission: approvalRemarksPermission,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_CLAIMS_DETAILS]: LoadingStatus.Success,
        },
      };
    }

    case GET_CLAIMS_DETAILS_FAILURE: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_CLAIMS_DETAILS]: LoadingStatus.Failure,
        },
      };
    }

    case GET_MULTI_TRF_CLAIMS_DETAILS_REQUEST: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_MULTI_TRF_CLAIMS_DETAILS]:
            LoadingStatus.Loading,
        },
      };
    }

    case GET_MULTI_TRF_CLAIMS_DETAILS_SUCCESS: {
      const { claim, claimConfig } = action.payload;
      return {
        ...state,
        trfClaimData: claim,
        trfClaimConfig: claimConfig?.claimConfig,
        trfClaimType: claimConfig?.claim,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_MULTI_TRF_CLAIMS_DETAILS]:
            LoadingStatus.Success,
        },
      };
    }

    case GET_MULTI_TRF_CLAIMS_DETAILS_FAILURE: {
      return {
        ...state,
        requestProcessing: {
          ...state.requestProcessing,
          [APIRequestStatus.GET_MULTI_TRF_CLAIMS_DETAILS]:
            LoadingStatus.Failure,
        },
      };
    }
    
    case SELECT_ENTITLEMENT: {
      const entitlement = action.payload;
      return {
        ...state,
        entitlement: entitlement
      };
    }

    default: {
      return state;
    }
  }
};

const sortedArray = (array = []) => {
  return array.sort(function (a, b) {
    if (Number(a.positionOrder) < Number(b.positionOrder)) return -1;
    if (Number(a.positionOrder) > Number(b.positionOrder)) return 1;
    return 0;
  });
};
