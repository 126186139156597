import { getFormBody } from '../../components/OnBoarding/Organization/utils/userOnboarding/userOnboarding.util';
import { BuilderServices } from '../../services/builder';
import {
  ADD_CONFIG,
  DELETE_MODAL,
  EDIT_MODAL_DATA,
  GET_ALL_FORMS_LIST_FAILURE,
  GET_ALL_FORMS_LIST_REQUEST,
  GET_ALL_FORMS_LIST_SUCCESS,
  GET_CLAIMS_DETAILS_FAILURE,
  GET_CLAIMS_DETAILS_REQUEST,
  GET_CLAIMS_DETAILS_SUCCESS,
  GET_CLAIM_FORM_CONFIG_FAILURE,
  GET_CLAIM_FORM_CONFIG_REQUEST,
  GET_CLAIM_FORM_CONFIG_SUCCESS,
  GET_MULTI_TRF_CLAIMS_DETAILS_FAILURE,
  GET_MULTI_TRF_CLAIMS_DETAILS_REQUEST,
  GET_MULTI_TRF_CLAIMS_DETAILS_SUCCESS,
  POST_CLAIM_INFO_FAILURE,
  POST_CLAIM_INFO_REQUEST,
  POST_CLAIM_INFO_SUCCESS,
  POST_CONFIG_INFO_FAILURE,
  POST_CONFIG_INFO_REQUEST,
  POST_CONFIG_INFO_SUCCESS,
  RESET_CONFIG,
  SELECT_ENTITLEMENT,
  SET_MODAL_DATA,
} from './actionTypes';

export const addConfig = (payload) => ({
  type: ADD_CONFIG,
  payload,
});

export const editModalData = (payload) => ({
  type: EDIT_MODAL_DATA,
  payload,
});

export const deleteModal = (payload) => ({
  type: DELETE_MODAL,
  payload,
});

const postConfigInfoRequest = () => ({
  type: POST_CONFIG_INFO_REQUEST,
});

const postConfigInfoSuccess = (payload) => ({
  type: POST_CONFIG_INFO_SUCCESS,
  payload,
});

const postConfigInfoFailure = () => ({
  type: POST_CONFIG_INFO_FAILURE,
});

const getConfigInfoRequest = () => ({
  type: GET_CLAIM_FORM_CONFIG_REQUEST,
});

const getConfigInfoSuccess = (payload) => ({
  type: GET_CLAIM_FORM_CONFIG_SUCCESS,
  payload,
});

const getConfigInfoFailure = () => ({
  type: GET_CLAIM_FORM_CONFIG_FAILURE,
});

const getAllFormsListRequest = () => ({
  type: GET_ALL_FORMS_LIST_REQUEST,
});

const getAllFormsListSuccess = (payload) => ({
  type: GET_ALL_FORMS_LIST_SUCCESS,
  payload,
});

const getAllFormsListFailure = () => ({
  type: GET_ALL_FORMS_LIST_FAILURE,
});

export const resetConfig = () => ({
  type: RESET_CONFIG,
});

const postClaimInfoRequest = () => ({
  type: POST_CLAIM_INFO_REQUEST,
});

const postClaimInfoSuccess = (payload) => ({
  type: POST_CLAIM_INFO_SUCCESS,
  payload,
});

const postClaimInfoFailure = () => ({
  type: POST_CLAIM_INFO_FAILURE,
});

const getDetailsInfoRequest = () => ({
  type: GET_CLAIMS_DETAILS_REQUEST,
});

const getDetailsInfoSuccess = (payload) => ({
  type: GET_CLAIMS_DETAILS_SUCCESS,
  payload,
});

const getDetailsInfoFailure = () => ({
  type: GET_CLAIMS_DETAILS_FAILURE,
});

const getTRFDetailsInfoRequest = () => ({
  type: GET_MULTI_TRF_CLAIMS_DETAILS_REQUEST,
});

const getTRFDetailsInfoSuccess = (payload) => ({
  type: GET_MULTI_TRF_CLAIMS_DETAILS_SUCCESS,
  payload,
});

const getTRFDetailsInfoFailure = () => ({
  type: GET_MULTI_TRF_CLAIMS_DETAILS_FAILURE,
});

export const entitlementId = (payload) => ({
  type: SELECT_ENTITLEMENT,
  payload,
});

export const postConfigInfo = (claimBody, claimConfig) => async (dispatch) => {
  dispatch(postConfigInfoRequest());
  const claim = getFormBody(claimBody);
  const claimList = claimConfig.map((item) => {
    const {
      fieldSize,
      minDate,
      maxDate,
      disablePast,
      disableFuture,
      percisionNumber,
      dateFormat,
      timeInMinutes,
      errorMessage,
      option,
      errorMessageValidationOptions,
      shouldDisable,
      ...rest
    } = item;
    return {
      ...rest,
      fieldSize: fieldSize?.id,
      percisionNumber: percisionNumber?.id,
      dateFormat: dateFormat?.id,
      minMinutesNextClaim: { timeInMinutes, errorMessage },
      validationOption: { option: option?.id, errorMessageValidationOptions },
    };
  });
  return new BuilderServices()
    .postConfigInfo({ claim, claimConfig: claimList })
    .then((res) => {
      dispatch(postConfigInfoSuccess({ claimBody, claimConfig }));
      return res;
    })
    .catch((err) => {
      dispatch(postConfigInfoFailure());
      throw err?.response?.data?.message || 'Error Saving';
    });
};

export const getClaimFormConfig = (claimId) => async (dispatch) => {
  dispatch(getConfigInfoRequest());
  return new BuilderServices()
    .getDetails(claimId)
    .then((res) => {
      dispatch(getConfigInfoSuccess(res));
      return res;
    })
    .catch(() => {
      dispatch(getConfigInfoFailure());
      return false;
    });
};

export const getAllFormsList = (roleId, searchInput) => async (dispatch) => {
  dispatch(getAllFormsListRequest());
  new BuilderServices()
    .getAllFormsList(roleId, searchInput)
    .then((res) => {
      dispatch(getAllFormsListSuccess(res));
    })
    .catch(() => {
      dispatch(getAllFormsListFailure());
    });
};

export const postClaimInfo =
  (claim, claimData, currentUserRoleId, departments) => async (dispatch) => {
    dispatch(postClaimInfoRequest());
    const claimConfig = claimData;
    return new BuilderServices()
      .postClaimInfo({
        claimConfig,
        claim: { id: claim.id },
        currentUserRoleId,
        departments,
      })
      .then((res) => {
        dispatch(postClaimInfoSuccess({ claim, claimData, id: res.id }));
        return res;
      })
      .catch((err) => {
        dispatch(postClaimInfoFailure());
        throw err?.response?.data?.message || 'Error Saving';
      });
  };

export const getClaimForm =
  (ClaimTypeId, currentUserRoleId, claimId) => async (dispatch) => {
    dispatch(getDetailsInfoRequest());
    return new BuilderServices()
      .getClaimsDetails(ClaimTypeId, currentUserRoleId, claimId)
      .then((res) => {
        dispatch(getDetailsInfoSuccess(res));
        return true;
      })
      .catch(() => {
        dispatch(getDetailsInfoFailure());
        return false;
      });
  };

export const getTRFClaimForm = (claimIds) => async (dispatch) => {
  dispatch(getTRFDetailsInfoRequest());
  return new BuilderServices()
    .getMultiClaimsDetailsTRF(claimIds)
    .then((res) => {
      dispatch(getTRFDetailsInfoSuccess(res));
      return true;
    })
    .catch((err) => {
      dispatch(getTRFDetailsInfoFailure());
      throw err?.response?.data?.message || 'Error Saving';
    });
};