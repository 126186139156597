import React, { useEffect, useState } from 'react';
import * as models from 'powerbi-models';
import { PowerBiService } from '../../services/powerBi';
import ButtonUI from '../../shared/components/ui-elements/ButtonUI';
import TryAgain from '../molecules/ErrorState/error.component';
import { useNavigate } from 'react-router-dom';
import { showToast } from '../../utils/common.util';
import { ToastThemes } from '../../constants/common';
import { Typography } from '@mui/material';

const PowerBIDashboardEmbed = (props) => {
  const { category } = props;
  const navigate = useNavigate();
  const [embedToken, setEmbedToken] = useState();
  const [embedTokenExpiration, setEmbedTokenExpiration] = useState();
  const [dashboard, setDashboard] = useState(null);
  const [timeInterval, setTimeInterval] = useState(null);
  const [error, setError] = useState(false);
  const [dashboardId, setDashboardId] = useState();
  const [groupId, setGroupId] = useState();

  const MINUTES_BEFORE_EXPIRATION = 5;
  const INTERVAL_TIME = 30000;

  useEffect(async () => {
    if (!dashboardId && !groupId ) {
      const dashboradData = await new PowerBiService()
        .getDashboardId()
        .catch((err) => {
          showToast(err?.response?.data?.message, ToastThemes.error);
          throw err?.response?.data?.message;
        });
      const { dashboardId, groupId } = dashboradData[0];
      setDashboardId(dashboardId);
      setGroupId(groupId);
    }
  }, []);

  useEffect(() => {
    if(dashboardId, groupId)
    checkTokenAndUpdate();
  }, [dashboardId, groupId]);

  useEffect(() => {
    return () => clearInterval(timeInterval);
  }, [timeInterval]);

  useEffect(() => {
    if (timeInterval) {
      clearInterval(timeInterval);
      setTimeInterval(null);
    }
    const interval = setInterval(async () => {
      await checkTokenAndUpdate();
    }, INTERVAL_TIME);
    setTimeInterval(interval);
    return () => {
      clearInterval(timeInterval);
      dashboard?.off('loaded');
    };
  }, [embedTokenExpiration]);

  useEffect(async () => {
    try {
      if (embedToken && dashboardId && groupId) {
        const config = {
          type: 'dashboard',
          tokenType: models.TokenType.Embed,
          embedUrl: `https://app.powerbi.com/dashboardEmbed?dashboardId=${dashboardId}&groupId=${groupId}`,
          accessToken: embedToken,
          id: dashboardId,
          settings: {
            navContentPaneEnabled: true,
          },
        };
        const embedContainer = document.getElementById('embedContainer');
        if (!dashboard) {
          setDashboard(window.powerbi.embed(embedContainer, config));
          dashboard?.on('loaded', () => {
            console.log('Dashboard loaded');
          });
        } else {
          await dashboard.setAccessToken(embedToken);
        }
      }
    } catch (err) {
      console.log(err.message);
    }
  }, [dashboardId, groupId, embedToken]);

  const checkTokenAndUpdate = async () => {
    const currentTime = Date.now();
    const expirationTime = Date.parse(embedTokenExpiration) || 0;
    const timeUntilExpiration = expirationTime - currentTime;
    const timeToUpdate = MINUTES_BEFORE_EXPIRATION * 60 * 1000;

    if (timeUntilExpiration <= timeToUpdate) {
      const accessTokenDetails = await new PowerBiService()
        .getDashboard(dashboardId, groupId)
        .catch(() => {
          clearInterval(timeInterval);
          setError(true);
        });
      const { expiration, token } = accessTokenDetails;
      setEmbedToken(token);
      setEmbedTokenExpiration(expiration);

      if (dashboard) {
        await dashboard.setAccessToken(token);
      }
    }
  };

  const handleGoBack = () => {
    // setShowDashboard(false);
    clearInterval(timeInterval);
  };

  const handleChange = () => {
    navigate('/new-claims/:id');
  };

  if (error) {
    return <TryAgain handleCloseClick={handleGoBack} />;
  }

  return (
    <div style={{ position: 'relative', height: '90vh', width: '100%' }}>
      <div
        id="embedContainer"
        style={{
          position: 'relative',
          height: '100%',
          width: '100%',
        }}
      />
      <div
        style={{
          position: 'absolute',
          top: 10,
          right: 10,
          zIndex: 1000,
        }}
      >
        <ButtonUI onclick={handleChange}>
          <Typography variant='button' textTransform={'capitalize'} fontSize="20px">{category}</Typography>
        </ButtonUI>
      </div>
    </div>
  );
};

export default PowerBIDashboardEmbed;
