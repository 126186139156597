import React, { useEffect, useState } from 'react';
import { ClaimServices } from '../../services/claims';
import ListView from '../../atoms/DataList/ListView';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ClaimsActionHeader from './ClaimsActionHeader';
import { Box } from '@mui/material';
import ModalDialog from '../../atoms/Modal/ModalDialog';
import ClaimRejectModal from '../claim-reject/claimRejectModal';
import { downloadWithCustomName as downloadFile } from '../download/Downloader';
import Loading from '../../atoms/Loading/loading';
import { getFormattedValues } from '../../utils/formatter';
import { BuilderServices } from '../../services/builder';
import { CheckCircleOutlineOutlined, CloudDownload, SwipeLeftOutlined } from '@mui/icons-material';

export default function ClaimList({ claimTypeId, claimTypeName }) {
  const claimService = new ClaimServices();
  const [rows, setRows] = useState([]);
  let rowsAfterFilter = [];
  const [filteredRows, setFilteredRows] = useState([]);
  const { currentUserRoleId } = useSelector((state) => state.main);
  const [selectedClaims, setSelectedClaims] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedClaimsWithDetails, setSelectedClaimsWithDetails] = useState(
    []
  );
  const [rejectModalOpen, setRejectModalOpen] = useState(false);
  const { currentUserRoleId: currentRole } = useSelector((state) => state.main);
  const [headCells, setHeadCells] = useState([
    {
      id: 'referenceNumber',
      label: 'REFERENCE NUMBER',
    },
    {
      id: 'type',
      label: 'CLAIM TYPES',
    },
    {
      id: 'createdAt',
      label: 'SUBMISSION DATE',
    },
    {
      id: 'amount',
      label: 'AMOUNT',
    },
    {
      id: 'status',
      label: 'STATUS',
    },
    // {
    //     id: 'action',
    //     label: 'ACTION',
    // },
  ]);
  // Success Model
  const [openModel, setOpenModel] = useState(false);
  const [modelTitle, setModelTitle] = useState('');
  const [modelDescription, setModelDescription] = useState('');
  const [downloadQueue, setDownloadQueue] = useState([]);

  // Permission section
  const { appPermissions } = useSelector((state) => state.main);
  const isEmployee =
    appPermissions && appPermissions['raise-claims'] ? true : false;
  const isApprover =
    appPermissions && appPermissions['approve-claims'] ? true : false;
  const navigate = useNavigate();


  // Download file section -------
  const downloadWithCustomName = (url, name, promise = true) =>
    downloadFile(url, name, promise);
  function CallBackFromList(filtered) {
    rowsAfterFilter = filtered;
  }
  const onDownload = () => {
    setLoading(true);
    const queryList = [];
    selectedClaimsWithDetails.forEach((claimData) => {
      if (claimData.attachment) {
        if (Array.isArray(claimData.attachment)) {
          claimData.attachment?.value.forEach((file, i) => {
            queryList.push(() =>
              downloadWithCustomName(file, `${claimData.referenceNumber}_${i}`)
            );
          });
        } else {
          queryList.push(() =>
            downloadWithCustomName(
              claimData.attachment.value,
              claimData.referenceNumber
            )
          );
        }
      }
    });
    setDownloadQueue(queryList);
  };

  useEffect(() => {
    if (downloadQueue && downloadQueue.length) {
      const temp = [...downloadQueue];
      if (temp[0]) {
        const [file, ...rest] = temp;
        file().finally(() => {
          setDownloadQueue([...rest]);
        });
      }
    } else {
      setLoading(false);
    }
  }, [downloadQueue]);
  // --------------
  const getClaimsData = async () => {
    setLoading(true);
    const claimServices = new ClaimServices();
    const res = await claimServices
      .getClaimsByClaimsType(claimTypeId, currentUserRoleId)
      .finally(() => setLoading(false));
    if (res.data && res.data.data) {
      let rows = res.data.data.map((claim) => ({
        referenceNumber: claim.referenceNumber,
        type: claim?.claimSubType?.name || claim?.claimType?.name,
        createdAt: claim.created_at
          ? getFormattedValues('YYYY/MM/DD', claim.created_at)
          : getFormattedValues('YYYY/MM/DD', claim.createdAt),
        amount: claim.amount,
        status: claim.status,
        id: { value: claim.id, hide: true },
        attachment: { value: claim.attachment, hide: true },
      }));
      setFilteredRows(rows);
      setRows(rows);
    }
  };
  const onRowClick = (index) => {
    if (rowsAfterFilter.length > 0) {
      const claimId = rowsAfterFilter[index]?.id?.value;
      if (claimId) {
        navigate(`/claims/view/${claimId}?ctId=${claimTypeId}`);
      }
    } else {
      const claimId = rows[index]?.id?.value;
      if (claimId) {
        navigate(`/claims/view/${claimId}?ctId=${claimTypeId}`);
      }
    }
  };

  useEffect(() => {
    getClaimsData();
  }, [claimTypeId]);

  const onSubmit = async ({
    claims,
    remark,
    reasons,
    status,
    paymentDate = null,
  }) => {
    try {
      setLoading(true);
      const response = await claimService.approveOrRejectClaims(
        {
          claims: selectedClaims,
          remark,
          reasons,
          status,
          paymentDate,
          approverRoleId: currentRole,
        },
        claimTypeId,
        currentUserRoleId
      );
      setLoading(false);
      setOpenModel(true);
      setModelTitle('Success');
      setModelDescription(response.data.message);
      return { success: response.data.message, error: null };
      // eslint-disable-next-line no-unreachable
    } catch (error) {
      setOpenModel(true);
      setModelTitle('Error');
      setModelDescription(error?.response?.data?.message || error.message);
      setLoading(false);
      return {
        success: false,
        error: error?.response?.data?.message || error.message,
      };
    }
  };
  const handleCloseModel = () => {
    setOpenModel(false);
    setModelTitle('');
    setModelDescription('');
    getClaimsData();
  };
  const onApprove = async () => {
    await onSubmit({
      claims: selectedClaims,
      status: 'APPROVED',
      paymentDate: new Date(),
    });
  };

  const selectAll = (event) => {
    const temp = [];
    let tempids = [];
    if (event.target.checked) {
      tempids = filteredRows.map((claim) => {
        temp.push(claim);
        return claim.id.value;
      });
    }
    setSelectedClaims(tempids);
    setSelectedClaimsWithDetails(temp);
  };

  const buttonOptions = [
    { title: 'Approve', handleClick: onApprove, icon: <CheckCircleOutlineOutlined sx={{ width: 20, height: 20 }} /> },
    { title: 'Send Back', handleClick: () => setRejectModalOpen(true), icon: <SwipeLeftOutlined sx={{ width: 20, height: 20 }} /> },
    { title: 'Download', handleClick: onDownload, icon: <CloudDownload style={{ marginRight: '5px' }} /> },
  ];

  return (
    <Loading loading={loading}>
      {isApprover && (
        <ClaimsActionHeader
          buttonOptions={buttonOptions}
        />
      )}
      <Box mx={-3}>
        <ListView
          showCheckbox={true}
          allowSelectAll={true}
          onSelectAllClick={selectAll}
          checkedItems={selectedClaims}
          setCheckedItems={setSelectedClaims}
          isDisabledCheckbox={() => { }}
          headCells={headCells}
          rowsData={filteredRows}
          onRowClick={onRowClick}
          callback={CallBackFromList}
        />

        <ClaimRejectModal
          open={rejectModalOpen}
          setOpen={setRejectModalOpen}
          claimDetails={{}}
          handleSubmit={onSubmit}
          onSuccessClose={getClaimsData}
          showClaimHeader={false}
        />
        <ModalDialog
          showModal={openModel}
          onClickClose={handleCloseModel}
          primaryAction={'Close'}
          onClickConfirm={handleCloseModel}
          title={modelTitle}
          description={modelDescription}
        />
      </Box>
    </Loading>
  );
}
