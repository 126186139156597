import React, { useState } from 'react';
import ModalDialog from '../../../../atoms/Modal/ModalDialog';
import { useDispatch, useSelector } from 'react-redux';
import CircularLoader from '../../../../atoms/CircularLoader/circular-loader';
import {
  LoadingStatus,
  ToastThemes,
  toastMessage,
} from '../../../../constants/common';
import DropDownBenefit from './drop-down-benefit.component';
import AllUserMultiselect from './all-user-multiselect-modal.component';
import { CompBenServices } from '../../../../services/compBen';
import { showToast } from '../../../../utils/common.util';
import {
  ToastMessages,
  stringSubstitute,
} from '../../../OnBoarding/Organization/constants/onboarding.constants';
import BenefitsDistribution from './benefits-distribution.component';

const ModalMapBenefit = (props) => {
  const { checkedItems, currentUserRoleId } = props;
  const [showModal, setshowModal] = useState(false);
  const [benefitModal, setBenefitModal] = useState(false);
  const [context, setContext] = useState('');
  const [postActionLoading, setPostActionLoading] = useState(-1);
  const [formInfo, setFormInfo] = useState({});
  const [benefitsDistributionList, setBenefitsDistributionList] = useState([]);

  const handleAssign = (context) => {
    setContext(context);
    setshowModal(true);
  };

  const handleAssignToClick = () => {
    setPostActionLoading(LoadingStatus.Loading);
    new CompBenServices()
      .assignBenefits({
        users: formInfo?.users.map((item) => Number(item.id)),
        benefitId: Number(checkedItems[0]?.id),
      })
      .then((res) => {
        if (res.length) {
          setBenefitsDistributionList(res);
          setBenefitModal(true);
        }
        showToast(
          ToastMessages.success.replace(stringSubstitute, toastMessage.update),
          ToastThemes.success
        );
        setPostActionLoading(LoadingStatus.Success);
        setFormInfo({});
      })
      .catch((err) => {
        showToast(err?.response?.data?.message, ToastThemes.error);
        setPostActionLoading(LoadingStatus.Failure);
        throw err?.response?.data?.message[0];
      });
  };

  const handleClose = () => {
    setshowModal(false);
    setFormInfo({});
  };

  const handleRenewClick = () => {
    setPostActionLoading(LoadingStatus.Loading);
    new CompBenServices()
      .renew()
      .then(() => {
        showToast(
          ToastMessages.success.replace(stringSubstitute, toastMessage.update),
          ToastThemes.success
        );
        setPostActionLoading(LoadingStatus.Success);
      })
      .catch((err) => {
        showToast(err?.response?.data?.message, ToastThemes.error);
        setPostActionLoading(LoadingStatus.Failure);
        throw err?.response?.data?.message[0];
      });
  };

  const modalMap = {
    assign: {
      title: 'Assign To',
      listComponent: (
        <AllUserMultiselect formInfo={formInfo} setFormInfo={setFormInfo} />
      ),
      primaryAction: 'Assign',
      onClickConfirm: () => handleAssignToClick(),
      severity: 'success',
      maxWidth: 'md',
      fullWidth: true,
    },
    renew : {
      title: 'Renew',
      primaryAction: 'Renew',
      onClickConfirm: () => handleRenewClick(),
      severity: 'success',
      maxWidth: 'md',
      fullWidth: true,
    }
  };
  return (
    <>
      <CircularLoader show={postActionLoading === LoadingStatus.Loading} />
      <DropDownBenefit
        checkedItems={checkedItems}
        handleAssignTo={handleAssign}
      />
      <ModalDialog
        severity={modalMap[context]?.severity}
        title={modalMap[context]?.title}
        listComponent={modalMap[context]?.listComponent}
        description={modalMap[context]?.description}
        primaryAction={modalMap[context]?.primaryAction}
        secondaryAction="Cancel"
        showModal={showModal}
        onClickClose={handleClose}
        onClickConfirm={modalMap[context]?.onClickConfirm}
        maxWidth={modalMap[context]?.maxWidth}
        fullWidth={modalMap[context]?.fullWidth}
        primaryButtonDisabled={modalMap[context]?.actionButtonDisabled}
        lhsActionButton={modalMap[context]?.lhsActionButton}
      />
      <ModalDialog
        title={`Benefits Distribution -- ${checkedItems[0]?.name}`}
        showModal={benefitModal}
        onClickClose={() => setBenefitModal(false)}
        maxWidth="fit-content"
        fullWidth={true}
        listComponent={
          <BenefitsDistribution
            list={benefitsDistributionList}
            benefitId={Number(checkedItems[0]?.id)}
          />
        }
        severity="success"
      />
    </>
  );
};

export default ModalMapBenefit;
