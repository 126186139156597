import React, { useEffect, useState } from 'react';
import { AddOutlined } from '@mui/icons-material';
import { Box, IconButton, Grid, Divider, Typography } from '@mui/material';
import { isEmpty } from 'lodash';
import AccordionTabs from '../../../../atoms/Accordian/Accordian';
import FormAccessConditional from './conditional-formacess.component';

const FormAccessMultipleConditional = (props) => {
  const { setFormInfo, conditional } = props;
  const { rules, ...rest } = conditional || {};

  const [conditionalRules, setConditionalRules] = useState(rules || [{}]);

  useEffect(() => {
      setFormInfo((prev) => ({
        ...prev,
        conditional: {
          rules: conditionalRules,
        },
      }));
    
  }, [conditionalRules]);

  return (
    <>
      {conditionalRules.map((item, index) => {
        const { conditions: storedConditions, ...rest } = item || {};
        return (
          <Box key={index} pl={15} position={'relative'}>
            <Box
              position={'absolute'}
              left={0}
              top={'5px'}
              border={'1px solid #00806E'}
              borderRadius={4}
              padding={'5px 17px 3px'}
              width="max-content"
            >
              {index === 0 ? (
                <Typography>if</Typography>
              ) : (
                <Typography>Else if</Typography>
              )}
            </Box>
            <AccordionTabs
              title={`Rule ${index + 1}`}
              bgColorSummary="rgba(167, 236, 226, 0.3)"
              tabDetails={
                <Box key={index} mb={2}>
                  <FormAccessConditional
                    index={index}
                    conditionalFormState={rest}
                    setConditionalRules={setConditionalRules}
                    storedConditions={storedConditions}
                    noOfConditions={conditionalRules.length}
                    setFormInfo={setFormInfo}
                  />
                </Box>
              }
            />
          </Box>
        );
      })}
      <IconButton
        onClick={() =>
          setConditionalRules((prev) => {
            return [...prev, {}];
          })
        }
        sx={{
          width: 'max-content',
        }}
      >
        <AddOutlined />
      </IconButton>
    </>
  );
};

export default FormAccessMultipleConditional;
