export const FETCH_USER_ONBOARDING_PERMISSIONS_REQUEST = 'FETCH_USER_ONBOARDING_PERMISSIONS_REQUEST';
export const FETCH_USER_ONBOARDING_PERMISSIONS_SUCCESS = 'FETCH_USER_ONBOARDING_PERMISSIONS_SUCCESS';
export const FETCH_USER_ONBOARDING_PERMISSIONS_FAILURE = 'FETCH_USER_ONBOARDING_PERMISSIONS_FAILURE';

export const FETCH_MY_PROFILE_PERMISSIONS_REQUEST = 'FETCH_MY_PROFILE_PERMISSIONS_REQUEST';
export const FETCH_MY_PROFILE_PERMISSIONS_SUCCESS = 'FETCH_MY_PROFILE_PERMISSIONS_SUCCESS';
export const FETCH_MY_PROFILE_PERMISSIONS_FAILURE = 'FETCH_MY_PROFILE_PERMISSIONS_FAILURE';

export const RESET_MODULE_PERMISSIONS = 'RESET_MODULE_PERMISSIONS';

export const FETCH_RECRUITING_PERMISSIONS_REQUEST = 'FETCH_RECRUITING_PERMISSIONS_REQUEST';
export const FETCH_RECRUITING_PERMISSIONS_SUCCESS = 'FETCH_RECRUITING_PERMISSIONS_SUCCESS';
export const FETCH_RECRUITING_PERMISSIONS_FAILURE = 'FETCH_RECRUITING_PERMISSIONS_FAILURE';

export const FETCH_PERFORMANCE_PERMISSIONS_REQUEST = 'FETCH_PERFORMANCE_PERMISSIONS_REQUEST';
export const FETCH_PERFORMANCE_PERMISSIONS_SUCCESS = 'FETCH_PERFORMANCE_PERMISSIONS_SUCCESS';
export const FETCH_PERFORMANCE_PERMISSIONS_FAILURE = 'FETCH_PERFORMANCE_PERMISSIONS_FAILURE';

export const FETCH_TIME_MANAGEMENT_PERMISSIONS_REQUEST = 'FETCH_TIME_MANAGEMENT_PERMISSIONS_REQUEST';
export const FETCH_TIME_MANAGEMENT_PERMISSIONS_SUCCESS = 'FETCH_TIME_MANAGEMENT_PERMISSIONS_SUCCESS';
export const FETCH_TIME_MANAGEMENT_PERMISSIONS_FAILURE = 'FETCH_TIME_MANAGEMENT_PERMISSIONS_FAILURE';

export const FETCH_PAYROLL_PERMISSIONS_REQUEST = 'FETCH_PAYROLL_PERMISSIONS_REQUEST';
export const FETCH_PAYROLL_PERMISSIONS_SUCCESS = 'FETCH_PAYROLL_PERMISSIONS_SUCCESS';
export const FETCH_PAYROLL_PERMISSIONS_FAILURE = 'FETCH_PAYROLL_PERMISSIONS_FAILURE';

export const FETCH_COMP_BEN_PERMISSIONS_REQUEST = 'FETCH_COMP_BEN_PERMISSIONS_REQUEST';
export const FETCH_COMP_BEN_PERMISSIONS_SUCCESS = 'FETCH_COMP_BEN_PERMISSIONS_SUCCESS';
export const FETCH_COMP_BEN_PERMISSIONS_FAILURE = 'FETCH_COMP_BEN_PERMISSIONS_FAILURE';