import { Box, Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import ListView from '../../../../atoms/DataList/ListView';
import { BenefitDistributionHeader } from '../../comp-ben-utlis/comp-ben-utils';
import theme from '../../../../foundation/theme';
import { LightTooltip } from '../../../../atoms/Tooltip/LightTooltip';
import { CompBenServices } from '../../../../services/compBen';
import { formatCurrency, showToast } from '../../../../utils/common.util';
import {
  ToastMessages,
  stringSubstitute,
} from '../../../OnBoarding/Organization/constants/onboarding.constants';
import { LoadingStatus, ToastThemes, toastMessage } from '../../../../constants/common';
import CircularLoader from '../../../../atoms/CircularLoader/circular-loader';

const BenefitsDistribution = (props) => {
  const { list, benefitId } = props;
  const [checkedItems, setCheckedItems] = useState([]);
  const [empList, setempList] = useState(list || []);
  const [postActionLoading, setPostActionLoading] = useState(-1);

  const handleCreateClick = async () => {
    setPostActionLoading(LoadingStatus.Loading);
    await new CompBenServices()
      .upsertBenefits({
        entitlements: checkedItems,
        benefitId,
        createNew: true,
      })
      .then((res) => {
        showToast(
          ToastMessages.success.replace(stringSubstitute, toastMessage.update),
          ToastThemes.success
        );
        const testIds = new Set(
          checkedItems.map((item) => `${item.id}-${item.employeeId}`)
        );

        const filteredList = empList.filter(
          (item) => !testIds.has(`${item.id}-${item.employeeId}`)
        );
        setempList(filteredList);
        setPostActionLoading(LoadingStatus.Success);
      })
      .catch((err) => {
        showToast(err?.response?.data?.message, ToastThemes.error);
          setPostActionLoading(LoadingStatus.Failure);
        throw err?.response?.data?.message;
      });
  };

  const handleUpdateClick = async () => {
    setPostActionLoading(LoadingStatus.Loading);
    await new CompBenServices()
      .upsertBenefits({
        entitlements: checkedItems,
        benefitId,
        createNew: false,
      })
      .then((res) => {
        showToast(
          ToastMessages.success.replace(stringSubstitute, toastMessage.update),
          ToastThemes.success
        );
        const testIds = new Set(
            checkedItems.map((item) => `${item.id}-${item.employeeId}`)
          );
  
          const filteredList = empList.filter(
            (item) => !testIds.has(`${item.id}-${item.employeeId}`)
          );
          setempList(filteredList);
        setPostActionLoading(LoadingStatus.Success);
      })
      .catch((err) => {
        showToast(err?.response?.data?.message, ToastThemes.error);
          setPostActionLoading(LoadingStatus.Failure);
        throw err?.response?.data?.message;
      });
  };

  const rowData = empList?.map((items) => {
    const {
      id,
      name,
      startDate,
      endDate,
      entitlementAmt,
      entitlementAmtUsed,
      entitlementBalanceAmt,
      fullName,
      employeeId,
      deptId, 
      deptName
    } = items;
    return {
      name: fullName,
      start: startDate,
      end: endDate,
      entitlementAmt: formatCurrency(Number(entitlementAmt)),
      entitlementBalance: formatCurrency(Number(entitlementBalanceAmt)),
      entitlementAmtUsed: formatCurrency(Number(entitlementAmtUsed)),
      deptName,
      id: {
        value: { id, employeeId, deptartmentId: deptId },
        hide: true,
      },
    };
  });

  return (
    <Box>
      <CircularLoader show={postActionLoading === LoadingStatus.Loading} />
      <Box display="flex" justifyContent="flex-end" gap={2}>
        <LightTooltip
          title={'This will create new entitlement for selected employees'}
          placement="left-start"
          arrow
          bgColor="#212B36"
          color={theme.palette.neutral.light60}
          sx={{ margin: 2 }}
          enterDelay={500}
        >
          <Button
            variant="text"
            color="primary"
            sx={{ textTransform: 'none', fontSize: 17 }}
            onClick={() => handleCreateClick()}
            disabled={!checkedItems.length}
          >
            Create
          </Button>
        </LightTooltip>
        <LightTooltip
          title={'This will over-write the entitlement for selected employees'}
          placement="bottom-end"
          arrow
          bgColor="#212B36"
          color={theme.palette.neutral.light60}
          sx={{ margin: 2 }}
          enterDelay={500}
        >
          <Button
            variant="text"
            color="primary"
            sx={{ textTransform: 'none', fontSize: 17 }}
            onClick={() => handleUpdateClick()}
            disabled={!checkedItems.length}
          >
            Update
          </Button>
        </LightTooltip>
      </Box>
      <ListView
        headCells={BenefitDistributionHeader}
        rowsData={rowData || []}
        showSerialNo={true}
        showCheckbox={true}
        setCheckedItems={setCheckedItems}
        checkedItems={checkedItems}
        allowSelectAll={true}
        showAddIcon={false}
      />
    </Box>
  );
};

export default BenefitsDistribution;
