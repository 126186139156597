export const ADD_CONFIG = 'ADD_CONFIG';

export const EDIT_MODAL_DATA = 'EDIT_MODAL_DATA';

export const DELETE_MODAL = 'DELETE_MODAL';

export const RESET_CONFIG = 'RESET_CONFIG';

export const POST_CONFIG_INFO_REQUEST = 'POST_CONFIG_INFO_REQUEST';
export const POST_CONFIG_INFO_SUCCESS = 'POST_CONFIG_INFO_SUCCESS';
export const POST_CONFIG_INFO_FAILURE = 'POST_CONFIG_INFO_FAILURE';

export const GET_CLAIM_FORM_CONFIG_REQUEST = 'GET_CLAIM_FORM_CONFIG_REQUEST';
export const GET_CLAIM_FORM_CONFIG_SUCCESS = 'GET_CLAIM_FORM_CONFIG_SUCCESS';
export const GET_CLAIM_FORM_CONFIG_FAILURE = 'GET_CLAIM_FORM_CONFIG_FAILURE';

export const GET_ALL_FORMS_LIST_REQUEST = 'GET_ALL_FORMS_LIST_REQUEST';
export const GET_ALL_FORMS_LIST_SUCCESS = 'GET_ALL_FORMS_LIST_SUCCESS';
export const GET_ALL_FORMS_LIST_FAILURE = 'GET_ALL_FORMS_LIST_FAILURE';

export const POST_CLAIM_INFO_REQUEST = 'POST_CLAIM_INFO_REQUEST';
export const POST_CLAIM_INFO_SUCCESS = 'POST_CLAIM_INFO_SUCCESS';
export const POST_CLAIM_INFO_FAILURE = 'POST_CLAIM_INFO_FAILURE';

export const GET_CLAIMS_DETAILS_REQUEST = 'GET_CLAIMS_DETAILS_REQUEST';
export const GET_CLAIMS_DETAILS_SUCCESS = 'GET_CLAIMS_DETAILS_SUCCESS';
export const GET_CLAIMS_DETAILS_FAILURE = 'GET_CLAIMS_DETAILS_FAILURE';

export const GET_MULTI_TRF_CLAIMS_DETAILS_REQUEST = 'GET_MULTI_TRF_CLAIMS_DETAILS_REQUEST';
export const GET_MULTI_TRF_CLAIMS_DETAILS_SUCCESS = 'GET_MULTI_TRF_CLAIMS_DETAILS_SUCCESS';
export const GET_MULTI_TRF_CLAIMS_DETAILS_FAILURE = 'GET_MULTI_TRF_CLAIMS_DETAILS_FAILURE';

export const SELECT_ENTITLEMENT = 'SELECT_ENTITLEMENT';