import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { useDebounce } from 'usehooks-ts';
import {
  APIRequestStatus,
  DebounceTimer,
  LoadingStatus,
} from '../../../../constants/common';
import { Box, Button } from '@mui/material';
import { DateRangePro } from '../../../molecules/DateRange/DateRangePro';
import { SettingsBackupRestoreOutlined } from '@mui/icons-material';
import ListView from '../../../../atoms/DataList/ListView';
import { UserBenefitHeader } from '../../comp-ben-utlis/comp-ben-utils';
import ApiSelectTag from '../../../../atoms/Select/ApiSelectTag';
import CircularLoader from '../../../../atoms/CircularLoader/circular-loader';
import TryAgain from '../../../molecules/ErrorState/error.component';
import { getAllUserBenefitsList } from '../../../../redux/benefits/action';
import { getFormattedValues } from '../../../../utils/formatter';
import UserBenefitModalMap from './modal-map.component';
import { EntitlementsService } from '../../../../services/entitlementsService';
import { formatCurrency } from '../../../../utils/common.util';

const AllUserBenefit = () => {
  const { currentUserRoleId } = useSelector((state) => state.main);
  const {
    userBenefitsList,
    requestProcessing: {
      [APIRequestStatus.GET_ALL_USER_BENEFITS_LIST]: listLoading,
    },
  } = useSelector((state) => state.benefits);

  const [checkedItems, setCheckedItems] = useState([]);
  const [option, setOption] = useState([]);
  const [benefitId, setBenefitId] = useState();

  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedDate, setSelectedDate] = useState([null, null]);
  const searchQuery = searchParams?.get('q');
  const debouncedVal = useDebounce(searchQuery, DebounceTimer);

  useEffect(async () => {
    if (currentUserRoleId) {
      await new EntitlementsService()
        .getBenfitsRole(currentUserRoleId, true)
        .then((res) => {
          const newList = [
            {
              id: null,
              name: 'Select',
              startDate: null,
              endDate: null,
            },
            ...res,
          ];
          setBenefitId(newList[1]?.id);
          setOption(newList);
        });
    }
  }, [currentUserRoleId]);

  useEffect(async () => {
    if (currentUserRoleId && benefitId) {
      dispatch(
        getAllUserBenefitsList(
          currentUserRoleId,
          benefitId,
          selectedDate[0]
            ? getFormattedValues('YYYY-MM-DD', selectedDate[0])
            : null,
          selectedDate[1]
            ? getFormattedValues('YYYY-MM-DD', selectedDate[1])
            : null,
          searchQuery
        )
      );
    }
  }, [currentUserRoleId, benefitId, debouncedVal, selectedDate]);

  useEffect(() => {
    setCheckedItems([]);
  }, [userBenefitsList]);

  const rowData = userBenefitsList?.map((items) => {
    const {
      id,
      employeeId,
      endDate,
      startDate,
      entitlementActionStatus,
      entitlementAmt,
      entitlementBalanceAmt,
    } = items;
    return {
      name: employeeId?.displayName,
      start: startDate,
      end: endDate,
      entitlementAmt: formatCurrency(Number(entitlementAmt)),
      entitlementBalance: formatCurrency(Number(entitlementBalanceAmt)),
      status: entitlementActionStatus?.name,
      id: {
        value: { id, endDate, entitlementActionStatus },
        hide: true,
      },
    };
  });

  const isDisabledReset = () => {
    return selectedDate?.[0] === null && selectedDate?.[1] === null;
  };

  const resetFilters = () => {
    setSelectedDate([null, null]);
    setBenefitId(option[1]?.id);
  };

  const handleDropDown = (event) => {
    const { value, name } = event.target;
    setBenefitId(value?.id);
  };

  if (listLoading === LoadingStatus.Failure) {
    return <TryAgain />;
  }
  return (
    <>
      <CircularLoader show={listLoading === LoadingStatus.Loading} />
      <Box sx={{ backgroundColor: 'white' }}>
        <Box display="flex" justifyContent="flex-end" gap={2} mb={1} p={2}>
          <Box mt={0.5}>
            <ApiSelectTag
              defaultValue={'Select Benefit Type'}
              fullWidth
              size="small"
              name="benefitId"
              value={benefitId}
              onchange={handleDropDown}
              dropDownList={option}
            />
          </Box>
          <DateRangePro
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
          />
          <Button
            startIcon={<SettingsBackupRestoreOutlined />}
            sx={{ textTransform: 'capitalize', mt: -1.5 }}
            disabled={isDisabledReset()}
            onClick={resetFilters}
          >
            Clear
          </Button>
          {
            <Box pr={1}>
              <UserBenefitModalMap
                checkedItems={checkedItems}
                currentUserRoleId={currentUserRoleId}
                benefitId={benefitId}
                searchQuery={searchQuery}
                selectedDate={selectedDate}
              />
            </Box>
          }
        </Box>
        <ListView
          headCells={UserBenefitHeader}
          rowsData={benefitId !== null ? rowData : [] || []}
          showSerialNo={true}
          showCheckbox={true}
          setCheckedItems={setCheckedItems}
          checkedItems={checkedItems}
          allowSelectAll={true}
          showAddIcon={false}
        />
      </Box>
    </>
  );
};

export default AllUserBenefit;
